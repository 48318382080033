<template>
	<footer
		class="w-full bg-cover bg-center bg-no-repeat px-5 py-7"
		:style="{
			backgroundImage: `url(${ThemeStore.footerImage})`,
			backgroundColor: ThemeStore.settings.footerColor,
		}"
	>
		<div
			class="mx-auto flex max-w-[1450px] flex-col text-white md:grid md:grid-cols-3 md:gap-2 lg:flex lg:flex-row lg:justify-between"
		>
			<!-- Newsletter -->
			<div class="col-span-2">
				<p class="mb-6 mt-4 font-normal text-white lg:mt-0">Newsletter</p>
				<FormNewsletterSignUp
					text="Get early access to Weedys new strain releases and other exclusive deals. We won't send you junk. Promise."
				/>
			</div>

			<!-- Socials -->
			<div>
				<p class="mb-6 mt-4 font-normal text-white lg:mt-0">Instagram</p>
				<NuxtLink
					to="https://www.instagram.com/weedys.official"
					no-prefetch
					class="focus-visible:border-1 block w-fit p-0.5 focus-visible:rounded-sm focus-visible:ring-1 focus-visible:ring-primary"
				>
					<IconsInstagram
						class="btn size-6 fill-primary hover:fill-primary-hover"
						aria-label="Instagram account icon"
					/>
				</NuxtLink>
			</div>

			<!-- Location -->
			<div class="col-span-2 mt-8 lg:mt-0">
				<p class="mb-6 mt-4 font-normal text-white lg:mt-0">Location</p>
				<div class="flex flex-col gap-5 *:text-white">
					<p>
						1118 S Telegraph Rd. Monroe, MI 48161 <br />
						Mon–Sun: 9 AM–9 PM
					</p>
					<p>Christmas Eve 9 AM-5 PM</p>
					<p>New Years Eve 9 AM - 7PM</p>
					<p>Thanksgiving, Christmas Day, New Years Day - Closed</p>
					<p>(877) 793-3397</p>
				</div>
			</div>

			<!-- Menus -->
			<div class="mt-8 flex w-2/3 justify-between gap-14 md:w-full lg:mt-0 lg:w-auto">
				<div>
					<p class="mb-6 mt-4 font-normal text-white lg:mt-0">Shop</p>
					<nav class="flex flex-col gap-3">
						<NuxtLink
							v-for="link in shopLinks"
							:key="link.url"
							:to="link.url"
							no-prefetch
							class="-ml-2 rounded-sm px-2 text-white hover:text-primary focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
							>{{ link.name }}</NuxtLink
						>
					</nav>
				</div>
				<div>
					<p class="mb-6 mt-4 font-normal text-white lg:mt-0">Info</p>
					<nav class="flex flex-col gap-3">
						<NuxtLink
							v-for="link in infoLinks"
							:key="link.url"
							:to="link.url"
							no-prefetch
							class="-ml-2 rounded-sm px-2 text-white hover:text-primary focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
							>{{ link.name }}</NuxtLink
						>
					</nav>
				</div>
			</div>
		</div>

		<div
			class="mx-auto mt-5 max-w-[1450px] border-t border-t-white border-opacity-40 py-2.5 text-xs font-light text-white"
		>
			© {{ new Date().getFullYear() }} Weedys. All rights reserved.
		</div>
	</footer>
</template>

<script setup lang="ts">
import { useThemeStore } from '../stores/themeStore';
import FormNewsletterSignUp from './ui/FormNewsletterSignUp.vue';

const ThemeStore = useThemeStore();

const shopLinks = [
	{ name: 'Flower', url: '/?category=flower' },
	{ name: 'Concentrate', url: '/?category=concentrate' },
	{ name: 'Prerolls', url: '/?category=preroll' },
	{ name: 'Packs', url: '/?category=bundle-pack' },
	{ name: 'Deals', url: '/dispensary-deals' },
];
const infoLinks = [
	{ name: 'Contact', url: '/contact' },
	{ name: 'Wholesale', url: '/wholesale' },
	{ name: 'Jobs', url: '/jobs' },
	{ name: 'Terms', url: '/terms-of-use' },
	{ name: 'Privacy', url: '/privacy-policy' },
	{ name: 'Accessibility', url: '/accessibility' },
	{ name: 'Returns', url: '/return-policy' },
];
</script>
