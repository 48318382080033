<template>
	<nav :class="{ mobile: HeaderStore.isMobileOrTablet }" class="relative">
		<ul
			class="flex list-none gap-8 p-0"
			:class="HeaderStore.isMobileOrTablet ? 'flex-col' : 'flex-row'"
		>
			<li
				:tabindex="0"
				:class="linkCss"
				class="group focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
				@mouseenter="dispensariesDropDownOpen = true"
				@mouseleave="dispensariesDropDownOpen = false"
				@keydown.enter="dispensariesDropDownOpen = !dispensariesDropDownOpen"
			>
				Dispensaries<IconsChevronDown
					class="-mr-4 h-8 w-8"
					:class="
						HeaderStore.isMobileOrTablet
							? 'fill-black'
							: 'fill-white group-hover:fill-primary'
					"
				/>

				<HeaderNavDropDown
					v-if="dispensariesDropDownOpen"
					:pages="[
						{ link: '/michigan-dispensary-near-ohio', title: 'Near Ohio' },
						{
							link: '/michigan-dispensary-near-indiana',
							title: 'Near Indiana',
						},
					]"
					:on-last-link-blur="() => (dispensariesDropDownOpen = false)"
				/>
			</li>
			<li
				:tabindex="0"
				:class="linkCss"
				class="group focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
				@mouseenter="dealsDropDownOpen = true"
				@mouseleave="dealsDropDownOpen = false"
				@keydown.enter="dealsDropDownOpen = !dealsDropDownOpen"
			>
				Deals<IconsChevronDown
					class="-mr-4 h-8 w-8"
					:class="
						HeaderStore.isMobileOrTablet
							? 'fill-black'
							: 'fill-white group-hover:fill-primary'
					"
				/>

				<HeaderNavDropDown
					v-if="dealsDropDownOpen"
					:pages="[
						{ link: '/dispensary-deals', title: 'All Dispensary Deals' },
						{ link: '/first-time-dispensary-deals', title: 'First Time Deal' },
						{ link: '/$100-ounces', title: '$100 Ounces' },
						{ link: '/dispensary-birthday-deals-michigan', title: 'Birthday Deal' },
					]"
					:on-last-link-blur="() => (dealsDropDownOpen = false)"
				/>
			</li>

			<li
				v-if="HeaderStore.brandLinks.length"
				:tabindex="0"
				:class="linkCss"
				class="group focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
				@mouseenter="brandsDropDownOpen = true"
				@mouseleave="brandsDropDownOpen = false"
				@keydown.enter="brandsDropDownOpen = !brandsDropDownOpen"
			>
				Brands<IconsChevronDown
					class="-mr-4 h-8 w-8"
					:class="
						HeaderStore.isMobileOrTablet
							? 'fill-black'
							: 'fill-white group-hover:fill-primary'
					"
				/>
				<HeaderNavDropDown
					v-if="brandsDropDownOpen"
					:pages="HeaderStore.brandLinks"
					:on-last-link-blur="() => (brandsDropDownOpen = false)"
				/>
			</li>
			<li :class="linkCss">
				<NuxtLink to="/cannabis-pricing" no-prefetch :class="linkCss" @click="closeMenu()"
					>Pricing</NuxtLink
				>
			</li>
			<li :class="linkCss">
				<NuxtLink to="/weedys-wear" no-prefetch :class="linkCss" @click="closeMenu()"
					>Merch</NuxtLink
				>
			</li>
			<li :class="linkCss">
				<NuxtLink to="/glass-jars" no-prefetch :class="linkCss" @click="closeMenu()"
					>Jars</NuxtLink
				>
			</li>
			<li
				:tabindex="0"
				:class="linkCss"
				class="group focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
				@mouseenter="learnDropDownOpen = true"
				@mouseleave="learnDropDownOpen = false"
				@keydown.enter="learnDropDownOpen = !learnDropDownOpen"
			>
				Learn
				<IconsChevronDown
					class="-mr-4 h-8 w-8"
					:class="
						HeaderStore.isMobileOrTablet
							? 'fill-black'
							: 'fill-white group-hover:fill-primary'
					"
				/>

				<HeaderNavDropDown
					v-if="learnDropDownOpen"
					:pages="[
						{ link: '/strain-library', title: 'Strain Library' },
						{
							link: '/learn/michigan-dispensary-purchase-limits',
							title: 'Purchase Limits',
						},
						{ link: '/learn/terpenes-over-potency', title: 'Terpenes Over Potency' },
						{ link: '/learn/cannabinoids', title: 'Cannabinoids' },
						{ link: '/learn/what-is-cannabis', title: 'What is Cannabis?' },
						{ link: '/learn/cannabis-anatomy', title: 'Cannabis Anatomy' },
						{ link: '/learn/cannabis-history', title: 'Cannabis History' },
					]"
					:on-last-link-blur="() => (learnDropDownOpen = false)"
				/>
			</li>
			<li :class="linkCss">
				<NuxtLink to="/jobs" no-prefetch :class="linkCss" @click="closeMenu()"
					>Jobs</NuxtLink
				>
			</li>
			<li :class="linkCss">
				<NuxtLink
					to="/contact"
					no-prefetch
					:class="linkCss"
					@click="closeMenu()"
					@blur="closeMenu()"
					>Contact</NuxtLink
				>
			</li>
			<li :class="linkCss">
				<NuxtLink to="/wholesale" no-prefetch :class="linkCss" @click="closeMenu()"
					>Wholesale</NuxtLink
				>
			</li>
		</ul>
	</nav>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useHeaderStore } from '../../stores/headerStore';
import HeaderNavDropDown from './HeaderNavDropDown.vue';

const HeaderStore = useHeaderStore();

const dispensariesDropDownOpen = ref(false);
const dealsDropDownOpen = ref(false);
const brandsDropDownOpen = ref(false);
const learnDropDownOpen = ref(false);

function closeMenu() {
	if (HeaderStore.isMobileOrTablet) {
		HeaderStore.closeMenu();
	}
	return;
}

const linkCss = ref(
	HeaderStore.isMobileOrTablet
		? 'flex items-center text-text text-xl font-semibold focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary focus-visible:rounded-sm'
		: 'flex items-center cursor-pointer text-white text-xl font-semibold hover:text-primary focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary focus-visible:rounded-sm',
);
</script>
